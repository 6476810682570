$(document).ready(function () {
  var $USEREMAIL = $('form#feedbackform input#user-email');
  var $PARTNEREMAIL = $('form#Partner-form input#partner-emails');
  var $FEEDBACKFULLNAME = $('form#feedbackform input#feedback-fullname');
  var $FEEDBACKDETAILS = $('form#feedbackform textarea#feedback-details');
  var $URL_IOSAPP_RATING = 'plateratewebtonative:openAppStore?appStoreURL=' + encodeURIComponent("https://itunes.apple.com/us/app/platerate/id1265907582?mt=8");
  var $URL_ANDROIDAPP_RATING = 'plateratewebtonative:openPlayStore';


  $('#yesRatingApp').click(function(){
    localStorage.setItem('Feedback',true);
    userRatedApp();
    if (check_iOSapp()){
      window.location = $URL_IOSAPP_RATING;
    } else {
      window.location = $URL_ANDROIDAPP_RATING;
    }
    $('#modalYesFeedback').modal('hide');
  })
  $('#noRatingApp').click(function () {
    $('#modalYesFeedback').modal('hide');
  })
  function check_iOSapp() {
    return (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)) ? true : false;
  }
  function check_androidApp() {
    return (window.navigator.userAgent == 'PlateRateForAndroid');
  }
  //Validate send form
  $USEREMAIL.blur(function(){
    $(this).parent().removeClass('has-error');
    $(this).parent().find('span.help-block').html('');
  });
  $FEEDBACKFULLNAME.blur(function () {
    $(this).parent().removeClass('has-error');
    $(this).parent().find('span.help-block').html('');
  });
  $FEEDBACKDETAILS.blur(function () {
    $(this).parent().removeClass('has-error');
    $(this).parent().find('span.help-block').html('');
  });

  $('#SendFeedback').click(function() {
    var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    var formURL = window.location.origin;
    var userEmail = $.trim($USEREMAIL.val());
    var feedbackFullName = $.trim($FEEDBACKFULLNAME.val());
    var feedbackDetails = $.trim($FEEDBACKDETAILS.val());
    var urlFeedback = $('form#feedbackform').attr('action');
    var isError = false;
    if (userEmail == '') {
      $USEREMAIL.parent().addClass('has-error');
      $USEREMAIL.parent().find('span.help-block').html('This field is required');
      isError = true;
    } else if (!validateEmail.test(userEmail)) {
      $USEREMAIL.parent().addClass('has-error');
      $USEREMAIL.parent().find('span.help-block').html('Please enter a valid email address');
      isError = true;
    }
    if (feedbackFullName == '') {
      $FEEDBACKFULLNAME.parent().addClass('has-error');
      $FEEDBACKFULLNAME.parent().find('span.help-block').html('This field is required');
      isError = true;
    } else if (feedbackFullName.length > 191) {
      $FEEDBACKFULLNAME.parent().addClass('has-error');
      $FEEDBACKFULLNAME.parent().find('span.help-block').html('This field maximum 191 characters are allowed');
      isError = true;
    }
    if (feedbackDetails == '') {
      $FEEDBACKDETAILS.parent().addClass('has-error');
      $FEEDBACKDETAILS.parent().find('span.help-block').html('This field is required');
      isError = true;
    } else if (feedbackDetails.length > 500) {
      $FEEDBACKDETAILS.parent().addClass('has-error');
      $FEEDBACKDETAILS.parent().find('span.help-block').html('This field maximum 500 characters are allowed');
      isError = true;
    }
    //Send mail
    if (!isError) {
      var dataSend = {
        email: userEmail,
        name: feedbackFullName,
        content: feedbackDetails,
        from: formURL }
      $.ajax({
        url: urlFeedback,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(dataSend),
        success: function (res) {
          $('#modalNoFeedback').modal('hide');
          if(res.code == 0) {
              Swal.fire({
                  title: "Success",
                  text: res.message,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3cb371",
                  confirmButtonText: "OK"
              });
          } else {
              Swal.fire("Oops", res.message, "error");
          }
        
        },
        error: function (err) {
          $('#modalNoFeedback').modal('hide');
          Swal.fire("Oops", "Please try again and reload the page once!", "error");
        },
      });
    }
  })
 
  
  
  $('#gettouchbutton').click(function(e) {
	  var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
	   var partnerName = $('#Partner_Name').val();
	   var PartnerRestaurant = $('#Partner_Restaurant').val();
	   var PartnerTitle = $('#Partner_title').val();
	   var PartnerPhonenumber = $('#Partner_phonenumber').val();
	   var PartnerEmail = $.trim($PARTNEREMAIL.val());	 
	   var PartnerZipcode = $('#Partner_zipcode').val();
       var urlFeedback = $('form#Partner-form').attr('action'); 
	   var isError = false;
	  if (PartnerEmail == '') {
      $PARTNEREMAIL.parent().addClass('has-error');
      $PARTNEREMAIL.parent().find('span.help-block').html('');
      isError = true;
    } else if (!validateEmail.test(PartnerEmail)) {
     $PARTNEREMAIL.parent().addClass('has-error');
     $PARTNEREMAIL.parent().find('span.help-block').html('');
      isError = true;
    }	 
    if (partnerName == '' ) {   
      isError = true;
    }	
    if (!isError) {			   
       var dataSend = {
        email: PartnerEmail,		
	PartnerTitle:PartnerTitle,
        PartnerRestaurant: PartnerRestaurant,
        PartnerZipcode: PartnerZipcode,
	PartnerPhonenumber:PartnerPhonenumber,
	partnerName:partnerName,
      };
		
      $.ajax({
        url: '/sendpartnerfeedback',
        method: 'POST',
        async: false,
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(dataSend),
    		dataType: 'json',
        success: function (res) {
            if(res.code == 0) {
			        $("#success_res_partner").text(res.msg);
			        $("#success_res_partner").css('display','block');
            } else {
			        $("#error_res_partner").text(res.msg);
			        $("#error_res_partner").css('display','block');
            }
        }
      });
    }	
    return false;
  })
  

});
  function setFeedback(){
   //Run handle set feedback
    if ((check_androidApp() && !localStorage.Feedback) || (check_iOSapp() && !localStorage.Feedback)) {
      feedback();
    }
  }
 
  function feedback() {
    setTimeToLocalStore();
  }
  function setTimeToLocalStore() {
    alert("jgjhgjgj")
    $('#modalFeedback').modal('show');
    /*if (typeof (Storage) !== "undefined") {
      if (localStorage.startFeedback && localStorage.endFeedback) {
        var milliseconds = Date.parse(localStorage.endFeedback) - Date.parse(localStorage.startFeedback);
        if (milliseconds >= 3000000) {
          localStorage.startFeedback = new Date();
          localStorage.endFeedback = new Date();
          $('#modalFeedback').modal('show');
        } else {
          localStorage.endFeedback = new Date();
        }
      } else {
        localStorage.startFeedback = new Date();
        localStorage.endFeedback = new Date();
      }
    }*/
  }
$(document).ready(function () {
  //If user already rated the app before logging in
  if($("#userRatedApp").val() == "false" && localStorage.Feedback){
    localStorage.setItem('Feedback',true);
    userRatedApp();
  }
})

function userRatedApp(){
  var dataSend = {};
  $.ajax({
    url: '/profileController/userRatedApp',
    type: 'POST',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(dataSend),
    success: function (res) {
      $("#userRatedApp").val('true');
    },
    error: function (err) {

    },
  });
}
